import {Avatar, Box, Flex, Heading, Spinner, Td, Tr} from "@chakra-ui/react";
import {getFullUrlFromTagTypeAndUrl, getTypeFromTagId, getUrlImgFromTagId} from "../tools";
import React, {useContext, useEffect, useMemo, useState} from "react";
import DataLoadContext from "../../../context/DataLoadContext";
import {getDataHotTag} from "../../api/getData";
import {motion} from "framer-motion";
import {SColorBG} from "../../../gatsby-plugin-theme-ui-old";
import theme from "../../../@chakra-ui/gatsby-plugin/theme";
import {Link} from "gatsby";

const MotionTr = motion(Tr)
const MotionTd = motion(Td)
const MotionBox = motion(Box)
const MotionFlex = motion(Flex)
const MotionAvatar = motion(Avatar)

const TagListItem = ({name,tagId,url,numInList,isSmallScreen})=> {

    const urlImg = getUrlImgFromTagId(tagId, 100)
    const [isHover, setIsHover] = useState(false)
    const [dataEvol, setDataEvol] = useState(null)
    const [dataNb, setDataNb] = useState(null)
    const [dataVocab, setDataVocab] = useState(null)

    const tagType = getTypeFromTagId(tagId)
    const fullUrl = getFullUrlFromTagTypeAndUrl(tagType, url)
    const {lastUpdate} = useContext(DataLoadContext);
    const lastUpdateHotTags = lastUpdate.hasOwnProperty("HotTags") ?
        lastUpdate["HotTags"] : null
    useEffect(() => {
        if (lastUpdateHotTags != null) {
            getDataHotTag(tagId, lastUpdateHotTags).then((d) => {
                if (d != null) {
                    setDataEvol({
                        t: d["t"],
                    })
                    setDataNb({
                        nb: d["nb24"]
                    })
                    setDataVocab({
                        lt: d["lt"]
                    })
                }

            });
        }
    }, [lastUpdateHotTags]);
    const colorBG = (numInList % 2) ? theme.colors.bg["700"] : theme.colors.bg["500"]

    if (!isSmallScreen) {
        const contentItem = <>
        <MotionTd position={"relative"}>
            <Flex sx={{justifyContent: 'center', position: "absolute", bottom: "0", left: 0, width: '100%'}}>
            <MotionBox variants={{
                hover: {opacity: 1, y: -5,transition: {delay: 0.1}
                }, default: {opacity: 0.4, y: -5}
            }} sx={{fontSize: 18, m: '4px', mr: '8px', fontWeight: 'bold', fontFamily: 'heading'}}>
                <Link  to={fullUrl}><Box sx={{
                    fontFamily: 'body',
                    fontWeight: 'bold',
                    color: 'text.100',
                    "borderRadius": "sketchy1",
                    "textDecoration": "none",
                    p: '8px',
                    "letterSpacing": "body",
                    "&:hover": {"bg": "bg.600", 'color': 'text'}
                }} as={"span"}>-> Plus de détail </Box></Link></MotionBox>
        </Flex>
        <MotionFlex sx={{justifyContent: 'center', flexDirection: 'column', alignItems: "center"}}>
            <MotionAvatar
            variants={{
                hover: {filter: 'grayscale(40%)', opacity: 1},
                default: {filter: 'grayscale(80%)', opacity: 0.8}
            }} src={urlImg} width={50} height={50}/>
            <Heading p={2} textAlign={"center"} fontSize={["16px", "18px"]}>{name}</Heading>
        </MotionFlex></MotionTd>
        <Td><TagItemValue typeInfo={"nb"} data={dataNb}/></Td>
        <Td><TagItemValue typeInfo={"evol"} data={dataEvol}/></Td>
        <Td><TagItemValue typeInfo={"vocab"} data={dataVocab}/></Td>
        </>
        return <MotionTr position={"relative"}
                         onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}
                         animate={(isHover) ? "hover" : "default"}
                         variants={{
                             hover: {backgroundColor: theme.colors.bg["900"]},
                             default: {backgroundColor: colorBG}
                         }}
        >
            {contentItem}
        </MotionTr>
    } else {
        return <Tr position={"relative"}><Td p={0}>
            <Flex bg={colorBG} width={"100%"}  p={[0,2]} pt={6} pb={4} flexWrap={"wrap"}>
            <Flex width={"100%"} pt={4} sx={{justifyContent: 'center', flexDirection: 'column', flexWrap: 'wrap', alignItems: "center"}}>
               <MotionAvatar
            sx={{filter: 'grayscale(40%)', opacity: 1}}
            src={urlImg} width={100} height={100}/>
            <Heading width={"100%"} p={2} textAlign={"center"} fontSize={["19px"]}>{name}</Heading>
        </Flex>
            <Box width={"50%"}><TagItemValue typeInfo={"nb"} data={dataNb}/></Box>
            <Box width={"50%"}><TagItemValue typeInfo={"evol"} data={dataEvol}/></Box>
            <Box width={"100%"}><TagItemValue  typeInfo={"vocab"} data={dataVocab} isSmallScreen={isSmallScreen}/></Box>
            <Flex sx={{justifyContent: 'center', width: '100%'}}>
            <Box sx={{fontSize: 18, m: '4px', mr: '8px', fontWeight: 'bold', fontFamily: 'heading'}}>
                <Link  to={fullUrl}><Box sx={{
                    fontFamily: 'body',
                    fontWeight: 'bold',color: 'text.100',"borderRadius": "sketchy1",
                    "textDecoration": "none",p: '8px',"letterSpacing": "body",
                    "&:hover": {"bg": "bg.600", 'color': 'text'}
                }} as={"span"}>-> Plus de détail </Box></Link></Box>
        </Flex>
        </Flex>
            </Td>
            </Tr>
    }
}


const TagItemValue = ({typeInfo, data, isSmallScreen = false})=>{
    const contentDiv = useMemo (() => {
        if (data == null){
            return <Flex justifyContent={"center"}><Spinner color="text.100" /></Flex>
        }
        switch (typeInfo) {
            case "nb":
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,minHeight: '50px',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <div>{data["nb"].toLocaleString()}</div>
                    </Flex>
                    <Box sx={{textAlign: 'center',textTransform:'uppercase',color:'text.100',
                        lineHeight:1.2, fontSize: 16, fontWeight: 'body', letterSpacing: 1.5}}>Tweets</Box>
                       </>
            case "evol":
                const evol = data["t"] - 100
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: '50px',
                        justifyContent: 'center',
                        flexDirection: 'column'
                    }}>
                        <div sx={{}}>{evol>0?"+":""}{evol}%</div>
                    </Flex>
                    <Box sx={{textAlign: 'center',textTransform:'uppercase',color:'text.100',
                        lineHeight:1.2, fontSize: 16, fontWeight: 'body', letterSpacing: 1.5}}>Tendance</Box>
                </>
            case "vocab":
                const listToken = data["lt"].map(token => token.TC)
                return <>
                    <Flex sx={{
                        textAlign: 'center',
                        fontSize: 25,
                        fontWeight: 'bold',
                        letterSpacing: 1.8,
                        minHeight: ['50px','150px','150px'],
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}>
                        <Flex sx={{justifyContent:"center",fontWeight:'bold',flexWrap:'wrap',p:'4px'}}>{listToken.map((token,i)=>
                            <Box key={i} sx={{bg:'secondary.100',ml:'4px',mr:'4px',mt:'4px',p:'2px', borderRadius:'4px',
                                borderColor:'secondary.300',borderStyle:'solid',borderWidth:'1px',
                                fontSize:'13px',color:'text.300',whiteSpace:'normal',fontFamily:'heading',fontWeight:'bold'}}>
                    {token} </Box>)} </Flex>
                    </Flex>
                     {isSmallScreen && <Box sx={{textAlign: 'center',textTransform:'uppercase',lineHeight:1.2,color:'text.100',
                        fontSize: 16, fontWeight: 'body', letterSpacing: 1.5, pb:'8px'}}>Vocabulaire</Box>
                    }
                    </>
            default:
                return <div></div>
        }
    },[typeInfo,data])

    return <Box sx={{minHeight:'100px',mt:'6px'
    }}> <Flex sx={{ml:'4px',mr:'4px',mt:0,borderRadius:'5px',minHeight:'80px', height:'100%',
                justifyContent:'center',flexDirection:'column'}}>
                       {contentDiv}
        </Flex>
            </Box>
}

export default TagListItem