import {LOG} from "../tools";
import React, {useEffect, useMemo, useState} from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th, useMediaQuery,
} from "@chakra-ui/react";
import TagListItem from "./TagListItem";

const TagTableSimple = ({listTagShown})=> {


    const [isSmallScreen, setIsSmallScreen] = useState(false);
      const [mediaQuery] = useMediaQuery("(max-width: 800px)");

      useEffect(() => {
        if(mediaQuery !== isSmallScreen){
          setIsSmallScreen(mediaQuery);
        }
      }, [mediaQuery])

    return <Table variant="simple">
        <Thead>
            <TrTable nameTag={"Personnalité"} isSmallScreen={isSmallScreen}/>
        </Thead>
        <Tbody>
            {listTagShown.map((t, i) => {
                return <TagListItem isSmallScreen={isSmallScreen} key={t.id} numInList={i} tagId={t.id} name={t.n}
                                    url={t.url}
                />
            })}
        </Tbody>
    </Table>
}

const TrTable = ({nameTag,isSmallScreen})=>{
    if(isSmallScreen){
        return <Tr >
          <Th textAlign={"center"} color={"text.100"}>{nameTag}</Th>
        </Tr>
    }else{
        return <Tr >
          <Th textAlign={"center"} color={"text.100"}>{nameTag}</Th>
          <Th textAlign={"center"} color={"text.100"}>Activité</Th>
          <Th textAlign={"center"} color={"text.100"}>Tendance</Th>
          <Th textAlign={"center"} color={"text.100"}>Vocabulaire</Th>
        </Tr>
    }
}

export default TagTableSimple