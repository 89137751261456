import * as React from "react"

import Layout from "../components/layout"
import {Container} from "@chakra-ui/react";
import {useContext, useEffect} from "react";
import DataLoadContext from "../context/DataLoadContext";
import {getDataPageLoad} from "../components/api/getData";
import PartisPage from "../components/partis/PartisPage";

const Parti = ({ }) => {

    const {updateLastUpdate} = useContext(DataLoadContext);
    useEffect(() => {
    getDataPageLoad("parti").then((d) =>{
        updateLastUpdate(d)
    });
  }, []);

  return (
    <Layout title={"Les partis politiques"}>
        <Container maxW="container.xl" sx={{
                margin: `10px auto`,
                pl:['2px','10px','10px'],
                pr:['2px','10px','10px'],
            }}>
        <PartisPage/>
        </Container>
    </Layout>
  )
}

export default Parti
